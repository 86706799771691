<template>
  <div class="water-control ly-wrap">
    <water-nav selected="2"></water-nav>
    <div class="error-wrap" v-if="isError">
      <div class="error-img"></div>
      <p>{{ errorMsg || '系统异常' }} </p>
    </div>
    <div class="ly-center water-control-wrap" v-else>
      <div class="nav-title"><i class="water-icon"></i>{{ pumpSwitch }}号絮凝沉淀池
        <div class="switch">
          <div class="st-item lf " @click="pumpSwitch = '1'" :class="[pumpSwitch=='1'?'on':'']">1号池排泥</div>
          <div class="st-item" @click="pumpSwitch = '2'" :class="[pumpSwitch=='2'?'on':'']">2号池排泥</div>
        </div>
      </div>
      <div class="water-control-bg">
        <div class="top-wrap">
          <div class="top-left">
            <div class="time">数据刷新时间：{{ loadTime | formatData }}</div>
            <div class="tp-lt-box">
              <div class="box-item">
                <p>排泥周期：<span class="value">{{ soilDisData.V1周期.data }}</span><span class="unit">H</span></p>
                <p>排泥时间：<span class="value">{{ soilDisData.V1排泥时间.data }}</span><span class="unit">S</span></p>
                <p>间隔：<span class="value">{{ soilDisData.V1间隔.data }}</span><span class="unit">S</span></p>
              </div>
              <div class="box-item big-item">
                <p>排泥状态：<span class="green">{{ soilDisData.V1STAT.data == 0 ? '未排泥' : '排泥中' }}</span></p>
                <p @click="handleModalControl(soilDisData.V1MODE.data,soilDisData.V1MODE.id)">控制模式：<span
                    class="value">{{
                    soilDisData.V1MODE.data == 0 ? '手动' : '自动'
                  }}</span></p>
                <p>倒计时：<span class="value">10</span><span class="unit">小时</span><span class="value">18</span><span
                    class="unit">分钟</span></p>
              </div>
            </div>
          </div>
          <div class="top-right">
            <div class="df-btn"
                 @click="handleAllControl(1,0,1,1,soilDisData.V1一键启动.id,'是否一键启动排泥阀?')">
              一键启动
            </div>
            <div class="df-btn"
                 @click="handleAllControl(1,0,1,1,soilDisData.V1一键停止.id,'是否一键停止排泥阀?')">
              一键停止
            </div>
          </div>
        </div>

        <div class="valve-wrap">
          <div class="valve-item">
            <p class="title">1号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV1.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV1.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('1号电磁阀',soilDisData.S1OV1.data,soilDisData.S1V1.id)">
              {{ soilDisData.S1OV1.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="valve-item">
            <p class="title">2号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV2.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV2.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('2号电磁阀',soilDisData.S1OV2.data,soilDisData.S1V2.id)">
              {{ soilDisData.S1OV2.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="valve-item">
            <p class="title">3号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV3.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV3.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('3号电磁阀',soilDisData.S1OV3.data,soilDisData.S1V3.id)">
              {{ soilDisData.S1OV3.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="valve-item">
            <p class="title">4号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV4.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV4.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('4号电磁阀',soilDisData.S1OV4.data,soilDisData.S1V4.id)">
              {{ soilDisData.S1OV4.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="valve-item">
            <p class="title">5号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV5.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV5.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('5号电磁阀',soilDisData.S1OV5.data,soilDisData.S1V5.id)">
              {{ soilDisData.S1OV5.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="valve-item">
            <p class="title">6号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV6.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV6.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('6号电磁阀',soilDisData.S1OV6.data,soilDisData.S1V6.id)">
              {{ soilDisData.S1OV6.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="valve-item">
            <p class="title">7号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV7.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV7.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('7号电磁阀',soilDisData.S1OV7.data,soilDisData.S1V7.id)">
              {{ soilDisData.S1OV7.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="valve-item">
            <p class="title">8号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV8.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV8.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('8号电磁阀',soilDisData.S1OV8.data,soilDisData.S1V8.id)">
              {{ soilDisData.S1OV8.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="valve-item">
            <p class="title">9号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV9.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV9.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('9号电磁阀',soilDisData.S1OV9.data,soilDisData.S1V9.id)">
              {{ soilDisData.S1OV9.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="valve-item">
            <p class="title">10号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV10.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV10.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('10号电磁阀',soilDisData.S1OV10.data,soilDisData.S1V10.id)">
              {{ soilDisData.S1OV10.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="valve-item">
            <p class="title">11号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV11.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV11.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('11号电磁阀',soilDisData.S1OV11.data,soilDisData.S1V11.id)">
              {{ soilDisData.S1OV11.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="valve-item">
            <p class="title">12号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV12.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV12.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('12号电磁阀',soilDisData.S1OV12.data,soilDisData.S1V12.id)">
              {{ soilDisData.S1OV12.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="valve-item">
            <p class="title">13号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV13.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV13.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('13号电磁阀',soilDisData.S1OV13.data,soilDisData.S1V13.id)">
              {{ soilDisData.S1OV13.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="valve-item">
            <p class="title">14号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV14.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV14.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('14号电磁阀',soilDisData.S1OV14.data,soilDisData.S1V14.id)">
              {{ soilDisData.S1OV14.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="valve-item">
            <p class="title">15号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV15.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV15.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('15号电磁阀',soilDisData.S1OV15.data,soilDisData.S1V15.id)">
              {{ soilDisData.S1OV15.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="valve-item">
            <p class="title">16号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV16.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV16.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('16号电磁阀',soilDisData.S1OV16.data,soilDisData.S1V16.id)">
              {{ soilDisData.S1OV16.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="valve-item">
            <p class="title">17号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV17.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV17.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('17号电磁阀',soilDisData.S1OV17.data,soilDisData.S1V17.id)">
              {{ soilDisData.S1OV17.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="valve-item">
            <p class="title">18号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV18.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV18.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('18号电磁阀',soilDisData.S1OV18.data,soilDisData.S1V18.id)">
              {{ soilDisData.S1OV18.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="valve-item">
            <p class="title">19号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV19.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV19.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('19号电磁阀',soilDisData.S1OV19.data,soilDisData.S1V19.id)">
              {{ soilDisData.S1OV19.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="valve-item">
            <p class="title">20号电磁阀</p>
            <div class="img" :class="[soilDisData.S1OV20.data==0?'':'green']"></div>
            <div class="df-btn " :class="[soilDisData.S1OV20.data == 0 ? '' : 'co-btn']"
                 @click="handleControl('20号电磁阀',soilDisData.S1OV20.data,soilDisData.S1V20.id)">
              {{ soilDisData.S1OV20.data == 0 ? '开启' : '关闭' }}
            </div>
          </div>
          <div class="clean"></div>
        </div>
      </div>
    </div>
    <div class="page-copyright">- 技术支持：云昇新控 -</div>
  </div>
</template>

<script>

import WaterNav from './modules/WaterNav.vue'
import moment from "moment";
import {postWaterVariableData, postSoilData} from '../../api/api.js'
import {MessageBox, Toast} from "mint-ui";
import {getSelectWater, getUserInfo} from "../../utils/auth";
import store from "../../store";

export default {
  name: 'WaterView',
  components: {WaterNav},
  filters: {
    formatData(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  computed: {
    soilDisData() {
      return this.$store.state.sundries.soilData.data
    },
    loadTime() {
      return this.$store.state.sundries.soilData.collectTime
    }
  },
  data() {
    return {
      // "soilDisData": {
      //   "S1OV1": {
      //     "id": "1414782367202578434",
      //     "name": "S1OV1",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV1"
      //   },
      //   "S1OV10": {
      //     "id": "1414782367227744257",
      //     "name": "S1OV10",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV10"
      //   },
      //   "S1OV11": {
      //     "id": "1414782367227744258",
      //     "name": "S1OV11",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV11"
      //   },
      //   "S1OV12": {
      //     "id": "1414782367227744259",
      //     "name": "S1OV12",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV12"
      //   },
      //   "S1OV13": {
      //     "id": "1414782367236132865",
      //     "name": "S1OV13",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV13"
      //   },
      //   "S1OV14": {
      //     "id": "1414782367236132866",
      //     "name": "S1OV14",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV14"
      //   },
      //   "S1OV15": {
      //     "id": "1414782367236132867",
      //     "name": "S1OV15",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV15"
      //   },
      //   "S1OV16": {
      //     "id": "1414782367244521474",
      //     "name": "S1OV16",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV16"
      //   },
      //   "S1OV17": {
      //     "id": "1414782367244521475",
      //     "name": "S1OV17",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV17"
      //   },
      //   "S1OV18": {
      //     "id": "1414782367244521476",
      //     "name": "S1OV18",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV18"
      //   },
      //   "S1OV19": {
      //     "id": "1414782367252910082",
      //     "name": "S1OV19",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV19"
      //   },
      //   "S1OV2": {
      //     "id": "1414782367202578435",
      //     "name": "S1OV2",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV2"
      //   },
      //   "S1OV20": {
      //     "id": "1414782367252910083",
      //     "name": "S1OV20",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV20"
      //   },
      //   "S1OV3": {
      //     "id": "1414782367202578436",
      //     "name": "S1OV3",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV3"
      //   },
      //   "S1OV4": {
      //     "id": "1414782367210967041",
      //     "name": "S1OV4",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV4"
      //   },
      //   "S1OV5": {
      //     "id": "1414782367210967042",
      //     "name": "S1OV5",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV5"
      //   },
      //   "S1OV6": {
      //     "id": "1414782367210967043",
      //     "name": "S1OV6",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV6"
      //   },
      //   "S1OV7": {
      //     "id": "1414782367219355650",
      //     "name": "S1OV7",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV7"
      //   },
      //   "S1OV8": {
      //     "id": "1414782367219355651",
      //     "name": "S1OV8",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV8"
      //   },
      //   "S1OV9": {
      //     "id": "1414782367219355652",
      //     "name": "S1OV9",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1OV9"
      //   },
      //   "S1V1": {
      //     "id": "1414782367148052483",
      //     "name": "S1V1",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V1"
      //   },
      //   "S1V10": {
      //     "id": "1414782367169024002",
      //     "name": "S1V10",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V10"
      //   },
      //   "S1V11": {
      //     "id": "1414782367169024003",
      //     "name": "S1V11",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V11"
      //   },
      //   "S1V12": {
      //     "id": "1414782367177412609",
      //     "name": "S1V12",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V12"
      //   },
      //   "S1V13": {
      //     "id": "1414782367177412610",
      //     "name": "S1V13",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V13"
      //   },
      //   "S1V14": {
      //     "id": "1414782367177412611",
      //     "name": "S1V14",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V14"
      //   },
      //   "S1V15": {
      //     "id": "1414782367185801218",
      //     "name": "S1V15",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V15"
      //   },
      //   "S1V16": {
      //     "id": "1414782367185801219",
      //     "name": "S1V16",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V16"
      //   },
      //   "S1V17": {
      //     "id": "1414782367185801220",
      //     "name": "S1V17",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V13"
      //   },
      //   "S1V18": {
      //     "id": "1414782367194189826",
      //     "name": "S1V18",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V14"
      //   },
      //   "S1V19": {
      //     "id": "1414782367194189827",
      //     "name": "S1V19",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V15"
      //   },
      //   "S1V2": {
      //     "id": "1414782367148052484",
      //     "name": "S1V2",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V2"
      //   },
      //   "S1V20": {
      //     "id": "1414782367194189828",
      //     "name": "S1V20",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V20"
      //   },
      //   "S1V3": {
      //     "id": "1414782367156441090",
      //     "name": "S1V3",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V3"
      //   },
      //   "S1V4": {
      //     "id": "1414782367156441091",
      //     "name": "S1V4",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V4"
      //   },
      //   "S1V5": {
      //     "id": "1414782367156441092",
      //     "name": "S1V5",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V5"
      //   },
      //   "S1V6": {
      //     "id": "1414782367160635394",
      //     "name": "S1V6",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V6"
      //   },
      //   "S1V7": {
      //     "id": "1414782367160635395",
      //     "name": "S1V7",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V7"
      //   },
      //   "S1V8": {
      //     "id": "1414782367160635396",
      //     "name": "S1V8",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V8"
      //   },
      //   "S1V9": {
      //     "id": "1414782367169024001",
      //     "name": "S1V9",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "S1V9"
      //   },
      //   "V1MODE": {
      //     "id": "1414782367131275265",
      //     "name": "V1MODE",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "V1模式"
      //   },
      //   "V1STAT": {
      //     "id": "1414775348076777474",
      //     "name": "V1STAT",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "排泥状态"
      //   },
      //   "V1一键停止": {
      //     "id": "1414782367148052482",
      //     "name": "V1一键停止",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "V1一键停止"
      //   },
      //   "V1一键启动": {
      //     "id": "1414782367139663875",
      //     "name": "V1一键启动",
      //     "data": "0",
      //     "unit": null,
      //     "zhName": "V1一键启动"
      //   },
      //   "V1周期": {
      //     "id": "1414774627642150914",
      //     "name": "V1周期",
      //     "data": "24",
      //     "unit": "H",
      //     "zhName": "V1周期"
      //   },
      //   "V1排泥时间": {
      //     "id": "1414782367139663874",
      //     "name": "V1排泥时间",
      //     "data": "10",
      //     "unit": "S",
      //     "zhName": "V1排泥时间"
      //   },
      //   "V1间隔": {
      //     "id": "1414782367139663873",
      //     "name": "V1间隔",
      //     "data": "5",
      //     "unit": "S",
      //     "zhName": "V1间隔"
      //   }
      // },
      waterId: null,
      isError: false,
      pumpSwitch: '1',
    }
  },
  mounted() {
    this.loadBase()
  },
  created() {
  },
  methods: {
    async loadBase() {
      const userInfo = await getUserInfo()
      const selectWater = await getSelectWater()
      this.waterId = selectWater.id
      const body = {
        mudId: '1415198250735546370',
        userId: userInfo.id
      }
      postSoilData(body).then(res => {
        this.isError = false
        if (res.data.success) {
          store.dispatch('SOIL_APP_MUD', res.data.result)
        } else {
          this.isError = true
          this.errorMsg = res.data.message || '异常'
        }
      })
    },
    // 一键操作
    handleAllControl(data, dataBefore, logType, typeCode, variableId, tips) {
      MessageBox.close()
      MessageBox.confirm(tips).then(action => {
        this.firstPost(data, dataBefore, logType, typeCode, variableId, this.waterId)
        setTimeout(() => {
          this.postData(0, 1, logType, typeCode, variableId, this.waterId)
        }, 1000);
      }).catch((err) => {
        console.log(err)
      });
    },
    // 更改控制模式
    handleModalControl(state, variableId) {
      let data = 0
      let tips = ''
      if (state == 0) {
        tips = '自动'
        data = 1
      } else {
        tips = '手动'
      }
      // 是否更改控制模式为 手动/自动
      MessageBox.close()
      MessageBox.confirm('是否更改控制模式为' + tips + '？').then(action => {
        this.postData(data, state, 1, 3, variableId, this.soilDisData.waterId)
      }).catch((err) => {
        console.log(err)
      });
    },
    // 统一控制
    handleControl(name, state, variableId) {
      // 判断控制模式
      if (this.soilDisData.V1MODE.data == 1) {
        Toast('当前是自动模式，请先更改控制模式为手动！');
        return
      }
      let data = 0
      let tips = ''
      if (state == 0) {
        tips = '启动'
        data = 1
      } else {
        tips = '停止'
      }
      MessageBox.close()
      MessageBox.confirm('是否' + tips + name + '？').then(action => {
        this.postData(data, state, 1, 2, variableId, this.waterId)
      }).catch((err) => {
        console.log(err)
      });
    },
    // 统一的脉冲请求 第一次
    firstPost(data, dataBefore, logType, typeCode, variableId, waterId) {
      const body = {
        data: data,
        dataBefore: dataBefore,
        logType: logType,
        typeCode: typeCode,
        variableId: variableId,
        waterId: waterId
      }
      postWaterVariableData(body)
    },
    // 统一的控制请求
    postData(data, dataBefore, logType, typeCode, variableId, waterId) {
      const body = {
        data: data,
        dataBefore: dataBefore,
        logType: logType,
        typeCode: typeCode,
        variableId: variableId,
        waterId: waterId
      }
      postWaterVariableData(body).then(res => {
        this.loadBase()
        Toast('操作成功！');
      })
    },

  },
  destroyed: function () { // 离开页面生命周期函数
  },
}
</script>

<style scoped lang="less">
.water-control {
  width: 100%;
  min-height: 100%;
  background-color: #ffffff;
}

@import '../../assets/less/waterControl.less';
</style>

